import PropTypes from 'prop-types';

import { CloseIcon } from 'components/atoms/Icons';

const Modal = ({ elementId, isOpen, closeModal, children }) => {
    return (
        <div className="modal-outer">
            <div
                className={`modal-container max-h-full overflow-y-auto scrollbar-hidden ${
                    isOpen ? 'open' : ''
                }`}
                id={elementId}
            >
                <div className="modal-body">
                    {isOpen && (
                        <button className="close-btn z-20 p-xxs absolute" onClick={closeModal}>
                            <CloseIcon fill="currentColor" className="w-5 h-5" />
                        </button>
                    )}
                    <div className="modal-inner relative z-10">{children}</div>
                </div>
            </div>
            {isOpen && <button className="modal-overlay" onClick={closeModal} />}
        </div>
    );
};

Modal.defaultProps = {
    isOpen: false,
    closeModal: () => {},
};

Modal.propTypes = {
    elementId: PropTypes.string,
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Modal;
