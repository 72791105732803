import PropTypes from 'prop-types';
import Autocomplete from 'components/organisms/SearchPage/Autocomplete';
import algoliasearch from 'algoliasearch';
import { InstantSearch } from 'react-instantsearch';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { useConsentState } from 'hooks/useConsentState';

const FormContainer = styled.div`
    input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #9ca3af;
        opacity: 1; /* Firefox */
    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #9ca3af;
    }

    input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #9ca3af;
    }

    .searchModal__label {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        line-height: normal;
        opacity: 1;
        font-family: Barlow;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    .aa-Form {
        background-color: transparent;
        border: 0;
        &:focus-within {
            box-shadow: none;
        }
    }
    .aa-Input {
        width: 100%;
        background: transparent;
        color: #fff;
        border-bottom: 1px solid #f2f2f2;
        outline: none;
        letter-spacing: inherit;
        font-size: 24px;
        padding: 20px 45px 20px 0;
        height: auto;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &:focus {
            outline: none;
        }
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 36px #1e1e1e inset;
            -webkit-text-fill-color: #fff;
        }
    }
    .aa-ClearButton {
        display: none;
    }
    .aa-SubmitButton {
        right: 0;
        top: 0;
        --border-color: var(--primary-button-color);
        width: 58px;
        height: 58px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        padding: 9px;
        border: 1px solid rgb(var(--border-color));
        transition: all 0.2s linear 0s;
        outline: none;
        cursor: pointer;
        background-color: rgb(var(--primary-button-color));
        color: rgb(var(--primary-button-bg));
        svg {
            color: rgb(var(--primary-button-bg));
        }
    }
`;

const SearchForm = ({ closeModal, isModalOpen }) => {
    const $ref = useRef(null);

    const attachEventListener = () => {
        const selectors = ['[data-action="focus-modal-search"]'];
        const accordionHandler = event => {
            if ($ref?.current) {
                let target = event.target;
                if (!target) {
                    // event.target is null
                    return;
                }

                if (!target.matches(selectors)) {
                    target = target.closest(selectors);
                }

                if (!target) {
                    // target.closest is null
                    return;
                }

                if (target.matches(selectors)) {
                    event.preventDefault();
                    event.stopPropagation();
                    event.stopImmediatePropagation();

                    // We must wait until the body margin top transition is complete before Algolia can correctly set panel position.
                    setTimeout(() => {
                        $ref?.current?.querySelector('.aa-input--searchModal')?.focus();
                    }, 300);
                }
            }
        };
        document.addEventListener('click', accordionHandler);

        return () => {
            document.removeEventListener('click', accordionHandler);
        };
    };

    const { analytics } = useConsentState();

    useEffect(() => {
        attachEventListener();
    }, []);

    const searchClient = algoliasearch(
        process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
        process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_API_KEY
    );

    // this prevents empty searches but not working with plugins (from docs https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/)
    // const searchClient = {
    //     ...algoliaClient,
    //     search(requests, arg) {
    //         if (requests.every(({ params }) => !params.query)) {
    //             console.log('empty request requests', requests);
    //             // eslint-disable-next-line no-undef
    //             return Promise.resolve({
    //                 results: requests.map(() => ({
    //                     hits: [],
    //                     nbHits: 0,
    //                     nbPages: 0,
    //                     page: 0,
    //                     processingTimeMS: 0,
    //                     hitsPerPage: 0,
    //                     exhaustiveNbHits: false,
    //                     query: '',
    //                     params: '',
    //                 })),
    //             });
    //         }

    //         console.log('requests', requests, arg);

    //         return algoliaClient.search(requests);
    //     },
    // };

    return (
        <FormContainer ref={$ref} className="search-form-container">
            {!!isModalOpen && (
                <InstantSearch indexName="shopify_products" searchClient={searchClient}>
                    <span className="searchModal__label">SEARCH</span>
                    <Autocomplete
                        searchClient={searchClient}
                        placeholder="Enter a keyword"
                        detachedMediaQuery="none"
                        classNames={{
                            panel: 'aa-Panel--searchModal',
                            form: 'aa-Form--searchModal',
                            input: 'aa-input--searchModal',
                        }}
                        modal="search"
                        closeModal={closeModal}
                        isModalOpen={isModalOpen}
                        insights={{
                            useCookie: analytics,
                        }}
                        future={{ preserveSharedStateOnUnmount: true }}
                    />
                </InstantSearch>
            )}
        </FormContainer>
    );
};

SearchForm.defaultProps = {
    closeModal: () => {},
};

SearchForm.propTypes = {
    closeModal: PropTypes.func,
};

export default SearchForm;
