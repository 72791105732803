import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Formik } from 'formik';

import {
    authenticateShopifyCustomer,
    getShopifyCustomer,
} from 'helpers/requests/customer-requests';

import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { BodyText } from 'components/atoms/Typography';
import { LockIcon, MailIcon, PlusIcon } from 'components/atoms/Icons';
import SiteContext from 'context/SiteContext';
import { useRouter } from 'next/router';
import { APPS, genericErrorMessage } from 'helpers/constants/general';
import { logInfo } from 'helpers/logging';
import { transformToElevarUserProperties } from 'helpers/elevar';

const LoginForm = ({ openModal, closeModal }) => {
    const {
        store: { afterLoginUrl, isLoginModalOpenOnLoad },
        setTradeDiscountValue,
        setLoginModalIsOpen,
        setAfterLoginUrl,
        setCustomer,
    } = useContext(SiteContext);
    const [accountError, setAccountError] = useState(null);
    const [initialValues, setInitialValues] = useState({
        email: '',
        password: '',
    });
    const router = useRouter();

    useEffect(() => {
        setLoginModalIsOpen(isLoginModalOpenOnLoad && router.asPath === '/');
    }, []);

    const submitForm = useCallback(
        async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                const { data } = await authenticateShopifyCustomer(values.email, values.password);

                const { customerAccessTokenCreate } = await { ...data };
                if (!customerAccessTokenCreate?.userErrors?.length) {
                    const { accessToken, expiresAt } =
                        customerAccessTokenCreate.customerAccessToken;
                    const expiryDate = new Date(expiresAt);
                    Cookies.set('ZIA_SESS', accessToken, {
                        expires: expiryDate,
                    });

                    Cookies.set('ZIA_USER', values.email, {
                        expires: expiryDate,
                    });

                    getShopifyCustomer(accessToken)
                        .then(res => {
                            const customer = res.data.customer;
                            setCustomer(customer);
                            window.customer = {
                                id: customer.id,
                                email: customer?.email,
                            };
                            /** Customer Logs into their account **/
                            const loginData = {
                                event: 'dl_login',
                                user_properties: {
                                    ...transformToElevarUserProperties({
                                        customer,
                                        customerLoggedIn: true,
                                    }),
                                },
                            };
                            window.ElevarDataLayer.push(loginData);
                            logInfo('Fire Elevar dl_login', APPS.FRONTEND, loginData);
                        })
                        .catch(err => console.error(err));

                    setLoginModalIsOpen(false);

                    await setTradeDiscountValue();
                    setAfterLoginUrl('/account/dashboard', false);
                    router.push(afterLoginUrl || '/account/dashboard');

                    return;
                }
                setAccountError(
                    "If you're having trouble logging in, please use the Sign Up form to create an account. If you are a trade member, please reach out to <a href='mailto:trade@ziatile.com' target='_blank'>trade@ziatile.com</a> for further assistance."
                );
            } catch (err) {
                console.error(`Error while login in; ${err}`);
                setAccountError(genericErrorMessage);
            }
            setSubmitting(false);
        },
        [afterLoginUrl]
    );

    return (
        <div className="login-form-container px-m-md overflow-auto scrollbar-hidden">
            <div className="flex flex-col lg:grid grid-cols-12 mb-sm lg:mb-md">
                <div className="border-b border-white/20 border-white pb-m-sm md:pb-0 md:border-0 lg:col-span-4 xl:col-span-6 mb-xs lg:mb-0">
                    <BodyText
                        color="#fff"
                        fontSize="24px"
                        fontSizeMobile="16px"
                        className="ml-sm md:ml-0"
                    >
                        Login
                    </BodyText>
                </div>
                <div className="block md:flex lg:col-span-8 xl:col-span-6">
                    <div className="flex justify-between mb-m-sm md:mb-0">
                        <BodyText color="#fff" opacity="0.6" margin="0 10px 0 0">
                            Forgot your password?
                        </BodyText>
                        <BodyText
                            color="#fff"
                            link
                            onClick={() => {
                                closeModal('login');
                                openModal('forgotpw');
                            }}
                            margin="0 40px 0 0"
                            marginMobile="0"
                        >
                            Reset it here
                        </BodyText>
                    </div>
                    <div className="flex justify-between">
                        <BodyText color="#fff" opacity="0.6" margin="0 10px 0 0">
                            Don&apos;t have an account?
                        </BodyText>
                        <BodyText
                            color="#fff"
                            link
                            onClick={() => {
                                closeModal('login');
                                openModal('register');
                            }}
                            margin="0 40px 0 0"
                            marginMobile="0"
                        >
                            Sign up
                        </BodyText>
                    </div>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};

                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={submitForm}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row">
                            <Input
                                error={!!errors.email || !!accountError}
                                inverted
                                size="medium"
                                name="email"
                                label="Email"
                                icon={<MailIcon fill="#aaa" />}
                                className="mb-xs md:mb-0 mr-sm md:overflow-visible"
                            >
                                <input
                                    name="email"
                                    placeholder="Your email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {!!errors.email && <div className="error">{errors.email}</div>}
                            </Input>
                            <Input
                                error={!!errors.email || !!accountError}
                                inverted
                                size="medium"
                                name="password"
                                label="Password"
                                icon={<LockIcon fill="#aaa" />}
                                className="mb-xs md:mb-0 mr-sm md:overflow-visible"
                            >
                                <input
                                    name="password"
                                    placeholder="Enter a password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {!!errors.email && <div className="error">{errors.email}</div>}
                            </Input>
                            <Button
                                buttonType="submit"
                                type="primary"
                                size="large"
                                inverted={true}
                                icon={<PlusIcon />}
                                label="Login"
                                disabled={isSubmitting}
                                maxWidth="187px"
                                className="mb-xs md:mb-0 self-end w-full"
                            />
                        </div>

                        {!!accountError && (
                            <BodyText
                                color="#ff3636"
                                dangerouslySetInnerHTML={{ __html: accountError }}
                            />
                        )}
                    </form>
                )}
            </Formik>
        </div>
    );
};

LoginForm.defaultProps = {
    openModal: () => {},
    closeModal: () => {},
};

LoginForm.propTypes = {
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
};

export default LoginForm;
