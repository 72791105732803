import { useMemo, useContext } from 'react';
import { Formik } from 'formik';
import Cookies from 'js-cookie';

import { countryCode, countryOptions, provinceOptions } from 'helpers/constants/address';

import SiteContext from 'context/SiteContext';

import { createCustomerAddress, updateCustomerAddress } from 'helpers/requests/customer-requests';

import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Button from 'components/atoms/Button';
import { BodyText } from 'components/atoms/Typography';
import { SaveIcon } from 'components/atoms/Icons';
import { genericErrorMessage } from 'helpers/constants/general';

import InputMask from 'react-input-mask';

const defaultValues = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    province: 'Alaska',
    country: 'United States',
    city: '',
    zip: '',
    company: '',
    phone: '',
};

const AddressForm = () => {
    const {
        store: { customerAddresses, activeAddressId },
        setCustomerDefaultAddress,
        onUpdateCustomerAddress,
        setAddressModalIsOpen,
        addCustomerAddress,
    } = useContext(SiteContext);

    const initialValues = useMemo(
        () => customerAddresses?.find(({ id }) => activeAddressId === id) || { ...defaultValues },
        [customerAddresses, activeAddressId]
    );

    const assignErrors = customerUserErrors => ({
        firstName:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('firstName')
            )?.message || null,
        lastName:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('lastName')
            )?.message || null,

        address1:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('address1')
            )?.message || null,

        address2:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('address2')
            )?.message || null,

        city:
            customerUserErrors?.find(customerUserError => customerUserError.field.includes('city'))
                ?.message || null,

        province:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('province')
            )?.message || null,

        zip:
            customerUserErrors?.find(customerUserError => customerUserError.field.includes('zip'))
                ?.message || null,

        country:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('country')
            )?.message || null,

        company:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('company')
            )?.message || null,

        phone:
            customerUserErrors?.find(customerUserError => customerUserError.field.includes('phone'))
                ?.message || null,
    });

    const assignStatusError = customerUserErrors =>
        customerUserErrors?.find(
            customerUserError =>
                customerUserError?.field?.includes('address') &&
                customerUserError?.field?.length === 1
        )?.message || null;

    const submitForm = async (values, { setSubmitting, setErrors, resetForm, setStatus }) => {
        setSubmitting(true);
        setStatus({
            error: null,
        });
        try {
            delete values.countryCodeV2;
            delete values.provinceCode;
            delete values.id;

            if (activeAddressId) {
                const { data } = await updateCustomerAddress(
                    Cookies.get('ZIA_SESS'),
                    activeAddressId,
                    values
                );

                if (data.customerAddressUpdate?.customerUserErrors?.length > 0) {
                    const customerUserErrors = data.customerAddressUpdate.customerUserErrors;
                    setErrors({
                        ...assignErrors(customerUserErrors),
                    });
                    setStatus({
                        error: assignStatusError(customerUserErrors),
                    });
                    return;
                }

                onUpdateCustomerAddress(
                    activeAddressId,
                    data.customerAddressUpdate.customerAddress
                );
            } else {
                const { data } = await createCustomerAddress(Cookies.get('ZIA_SESS'), values);

                if (data?.customerAddressCreate?.customerUserErrors?.length > 0) {
                    const customerUserErrors = data.customerAddressCreate.customerUserErrors;
                    setErrors({
                        ...assignErrors(customerUserErrors),
                    });
                    setStatus({
                        error: assignStatusError(customerUserErrors),
                    });
                    return;
                }

                addCustomerAddress(data.customerAddressCreate.customerAddress);

                if (customerAddresses.length === 0) {
                    setCustomerDefaultAddress(data.customerAddressCreate.customerAddress);
                }

                resetForm();
            }
            setAddressModalIsOpen(false);
            setSubmitting(false);
        } catch (err) {
            console.error(
                `Error while ${activeAddressId ? 'updating' : 'creating'} address; ${err}`
            );
            setStatus({
                error: genericErrorMessage,
            });
            setSubmitting(false);
        }
    };

    return (
        <div className="h-full">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'Required';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Required';
                    }
                    if (!values.address1) {
                        errors.address1 = 'Required';
                    }
                    if (!values.city) {
                        errors.city = 'Required';
                    }
                    if (!values.zip) {
                        errors.zip = 'Required';
                    }

                    return errors;
                }}
                onSubmit={submitForm}
            >
                {({
                    values,
                    errors,
                    status,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form
                        className="relative address-form-container px-m-md"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <div className="flex flex-col lg:grid grid-cols-12 gap-x-40px">
                            <div className="border-b border-white/20 border-white pb-m-sm md:pb-0 md:border-0 md:col-span-2 xl:col-span-2">
                                <BodyText
                                    color="#fff"
                                    fontSize="24px"
                                    fontSizeMobile="16px"
                                    className="ml-sm md:ml-0"
                                >
                                    Edit address
                                </BodyText>
                            </div>
                        </div>
                        <div className="overflow-auto scrollbar-hidden flex flex-col pt-xs md:pt-md md:grid grid-cols-12 gap-x-40px gap-y-40px">
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Input
                                    error={!!errors.firstName}
                                    inverted
                                    size="medium"
                                    name="firstName"
                                    label="First Name *"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="firstName"
                                        placeholder="Your first name"
                                        value={values.firstName || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.firstName && (
                                        <div className="error">{errors.firstName}</div>
                                    )}
                                </Input>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Input
                                    error={!!errors.lastName}
                                    inverted
                                    size="medium"
                                    name="lastName"
                                    label="Last Name *"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="lastName"
                                        placeholder="Your last name"
                                        value={values.lastName || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.lastName && (
                                        <div className="error">{errors.lastName}</div>
                                    )}
                                </Input>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-6 xl:col-span-4">
                                <Input
                                    error={!!errors.address1}
                                    inverted
                                    size="medium"
                                    name="address 1"
                                    label="Address 1 *"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="address1"
                                        placeholder="Your address"
                                        value={values.address1 || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.address1 && (
                                        <div className="error">{errors.address1}</div>
                                    )}
                                </Input>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-6 xl:col-span-4">
                                <Input
                                    error={!!errors.address2}
                                    inverted
                                    size="medium"
                                    name="address 2"
                                    label="Address 2"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="address2"
                                        placeholder="Apt., suite, unit, etc."
                                        value={values.address2 || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.address2 && (
                                        <div className="error">{errors.address2}</div>
                                    )}
                                </Input>
                            </div>

                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Input
                                    error={!!errors.city}
                                    inverted
                                    size="medium"
                                    name="city"
                                    label="Town / City *"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="city"
                                        placeholder="Your town or city"
                                        value={values.city || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.city && <div className="error">{errors.city}</div>}
                                </Input>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Select
                                    inverted={true}
                                    name="country"
                                    label="Country / Region"
                                    placeholder="Select a country"
                                    error={!!errors.country}
                                    options={countryOptions}
                                    value={values.country || ''}
                                    onChange={e => {
                                        handleChange(e);
                                        values.province = Object.keys(
                                            provinceOptions[e.target.value]
                                        )[0];
                                    }}
                                    onBlur={handleBlur}
                                />
                                {!!errors.country && <div className="error">{errors.country}</div>}
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Select
                                    inverted={true}
                                    name="province"
                                    label={values.country === 'Canada' ? 'Province' : 'State'}
                                    placeholder={`Select a ${
                                        values.country === 'Canada' ? 'province' : 'state'
                                    }`}
                                    error={!!errors.province}
                                    options={
                                        provinceOptions[values.country] ||
                                        provinceOptions['United States']
                                    }
                                    value={values.province || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {!!errors.province && (
                                    <div className="error">{errors.province}</div>
                                )}
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Input
                                    error={!!errors.zip}
                                    inverted
                                    size="medium"
                                    name="zip"
                                    label="Zip *"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="zip"
                                        placeholder="Your zip code"
                                        value={values.zip || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.zip && <div className="error">{errors.zip}</div>}
                                </Input>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Input
                                    error={!!errors.company}
                                    inverted
                                    size="medium"
                                    name="company"
                                    label="company name"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="company"
                                        placeholder="Your company name"
                                        value={values.company || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.company && (
                                        <div className="error">{errors.company}</div>
                                    )}
                                </Input>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Input
                                    error={!!errors.phone}
                                    inverted
                                    size="medium"
                                    name="phone"
                                    label="phone"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <InputMask
                                        mask={`${countryCode?.[values.country] || '+1'}9999999999`}
                                        maskChar=""
                                        name="phone"
                                        placeholder="Your phone"
                                        value={values.phone || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.phone && <div className="error">{errors.phone}</div>}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer pt-4 z-50 w-full md:py-0 md:absolute md:bottom-0 lg:top-10 lg:right-0 lg:w-48 lg:bottom-auto">
                            <Button
                                type="primary"
                                size="large"
                                buttonType="submit"
                                inverted={true}
                                icon={<SaveIcon />}
                                label="Save Address"
                                className="w-full"
                                disabled={isSubmitting}
                            />
                        </div>
                        {status?.error && (
                            <BodyText className="absolute top-full" color="#ff3636">
                                {status.error}
                            </BodyText>
                        )}
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default AddressForm;
