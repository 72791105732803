import { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/atoms/Link';
import { Formik } from 'formik';
import Cookies from 'js-cookie';

import {
    createShopifyCustomer,
    authenticateShopifyCustomer,
    getShopifyCustomer,
} from 'helpers/requests/customer-requests';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { BodyText } from 'components/atoms/Typography';
import { PlusIcon } from 'components/atoms/Icons';
import { customerCreateSuccess } from 'helpers/constants/customer';
import { useRouter } from 'next/router';
import SiteContext from 'context/SiteContext';
import { logInfo } from 'helpers/logging';
import { APPS } from 'helpers/constants/general';
import { transformToElevarUserProperties } from 'helpers/elevar';

const RegisterForm = ({ openModal, closeModal }) => {
    const { setRegisterModalIsOpen, setCustomer } = useContext(SiteContext);

    const router = useRouter();

    const [accountAlert, setAccountAlert] = useState({
        message: null,
        severity: null,
    });

    const submitForm = useCallback(async values => {
        try {
            const customerRes = await createShopifyCustomer({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
            });

            // Customer create error
            if (customerRes.data.errors && customerRes.data.errors.length) {
                setAccountAlert({
                    message: customerRes.data.errors[0].message,
                    severity: 'error',
                });
                return;
            }

            const { customerCreate } = { ...customerRes?.data?.data };

            // New customer created
            if (!customerCreate?.userErrors?.length) {
                setAccountAlert({
                    message: customerCreateSuccess,
                    severity: 'success',
                });
                try {
                    // set cookie with access token / exp.date
                    const { data } = await authenticateShopifyCustomer(
                        values.email,
                        values.password
                    );

                    const { customerAccessTokenCreate } = await { ...data };

                    if (!customerAccessTokenCreate?.userErrors?.length) {
                        const { accessToken, expiresAt } = {
                            ...customerAccessTokenCreate.customerAccessToken,
                        };

                        const expiryDate = new Date(expiresAt);
                        Cookies.set('ZIA_USER', values.email, {
                            expires: expiryDate,
                        });
                        Cookies.set('ZIA_SESS', accessToken, {
                            expires: expiryDate,
                        });

                        setRegisterModalIsOpen(false);

                        getShopifyCustomer(accessToken)
                            .then(res => {
                                const customer = res.data.customer;
                                setCustomer(customer);
                                window.customer = {
                                    id: customer.id,
                                    email: customer?.email,
                                };

                                const user_properties = transformToElevarUserProperties({
                                    customer,
                                    customerLoggedIn: true,
                                });
                                /** Customer creates new account **/
                                const signupData = {
                                    event: 'dl_sign_up',
                                    user_properties,
                                };
                                window.ElevarDataLayer.push(signupData);
                                logInfo('Fire Elevar dl_sign_up', APPS.FRONTEND, signupData);

                                /** Customer Logs into their account **/
                                const loginData = {
                                    event: 'dl_login',
                                    user_properties,
                                };
                                window.ElevarDataLayer.push(loginData);
                                logInfo('Fire Elevar dl_login', APPS.FRONTEND, loginData);
                            })
                            .catch(err => console.error(err));

                        router.push('/account/dashboard');
                    } else {
                        setAccountAlert({
                            message: `${customerCreateSuccess} Failed to login. <a href="/account/login>Please login here.</a>`,
                            severity: 'info',
                        });
                    }
                } catch (err) {
                    console.error(`Error while login in; ${err}`);
                    setAccountAlert({
                        message: 'Failed to login. <a href="/account/login">Please login here.</a>',
                        severity: 'error',
                    });
                }

                return;
            }

            // Customer exists but disabled
            if (
                customerCreate.userErrors[0].message.includes(
                    'please click the link included to verify your email address'
                )
            ) {
                setAccountAlert({
                    message: customerCreate.userErrors[0].message,
                    severity: 'success',
                });
                return;
            }

            // Customer exists and enabled
            if (customerCreate.userErrors[0].message === 'Email has already been taken') {
                setAccountAlert({
                    message: 'Email has already been taken.',
                    severity: 'error',
                });
                return;
            }

            // Password too short
            if (customerCreate.userErrors[0].message.includes('Password is too short')) {
                setAccountAlert({
                    message: 'Password is too short (minimum is 5 characters)',
                    severity: 'error',
                });
                return;
            }
        } catch (err) {
            console.error(`Error while signing up: ${err}`);
            setAccountAlert({
                message: 'Something went wrong, please try again.',
                severity: 'error',
            });
        }
    }, []);

    return (
        <div className="register-form-container px-m-md overflow-auto scrollbar-hidden">
            <div className="flex flex-col lg:grid grid-cols-12 gap-x-40px mb-sm md:mb-md">
                <div className="border-b border-white/20 border-white pb-m-sm md:pb-0 md:border-0 md:col-span-2 xl:col-span-2">
                    <BodyText
                        color="#fff"
                        fontSize="24px"
                        fontSizeMobile="16px"
                        className="ml-sm md:ml-0"
                    >
                        Signup
                    </BodyText>
                </div>
                <div className="hidden md:block md:col-span-6 xl:col-span-4">
                    <BodyText color="#fff" opacity="0.6">
                        From your dashboard you can view your recent orders, manage your shipping
                        and billing addresses, and edit your account details.
                    </BodyText>
                </div>
                <div className="block mt-xs md:flex xl:mt-0 md:col-span-12 xl:col-span-6  xl:justify-center">
                    <div className="flex justify-between mb-m-sm md:mb-0">
                        <BodyText color="#fff" opacity="0.6" margin="0 10px 0 0">
                            Want a trade account?
                        </BodyText>
                        <Link href="/trade-program" onClick={() => closeModal('register')}>
                            <BodyText color="#fff" link margin="0 40px 0 0">
                                Signup here
                            </BodyText>
                        </Link>
                    </div>
                    <div className="flex justify-between">
                        <BodyText color="#fff" opacity="0.6" margin="0 10px 0 0">
                            Have an account?
                        </BodyText>
                        <BodyText
                            color="#fff"
                            link
                            onClick={() => {
                                closeModal('register');
                                openModal('login');
                            }}
                            margin="0 40px 0 0"
                        >
                            Login
                        </BodyText>
                    </div>
                </div>
            </div>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'Required';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Required';
                    }
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                    }
                    if (!values.password) {
                        errors.password = 'Required';
                    }
                    if (values.password && values.password.length < 5) {
                        errors.password = 'Password is too short (minimum is 5 characters)';
                    }
                    return errors;
                }}
                onSubmit={submitForm}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    /* and other goodies */
                }) => (
                    <form
                        autoComplete="off"
                        className="minus-header-footer-cont"
                        onSubmit={handleSubmit}
                    >
                        <div className="flex flex-col md:grid grid-cols-12 gap-x-40px">
                            <div className="mb-m-sm md:hidden">
                                <BodyText color="#fff" opacity="0.6">
                                    From your dashboard you can view your recent orders, manage your
                                    shipping and billing addresses, and edit your account details.
                                </BodyText>
                            </div>
                            <div className="flex mb-m-sm md:justify-between md:col-span-8 md:mb-0 xxl:col-span-4">
                                <div className="mr-m-sm flex-1 md:mb-sm md:mr-sm">
                                    <Input
                                        error={!!errors.firstName}
                                        inverted
                                        size="medium"
                                        name="firstName"
                                        label="First Name"
                                        className="md:overflow-visible md:h-60px"
                                    >
                                        <input
                                            name="firstName"
                                            placeholder="Your first name"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {!!errors.firstName && (
                                            <div className="error">{errors.firstName}</div>
                                        )}
                                    </Input>
                                </div>
                                <div className="flex-1 md:mb-sm">
                                    <Input
                                        error={!!errors.lastName}
                                        inverted
                                        size="medium"
                                        name="lastName"
                                        label="Last Name"
                                        className="md:overflow-visible md:h-60px"
                                    >
                                        <input
                                            name="lastName"
                                            placeholder="Your last name"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {!!errors.lastName && (
                                            <div className="error">{errors.lastName}</div>
                                        )}
                                    </Input>
                                </div>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 xxl:col-span-3">
                                <Input
                                    error={
                                        !!errors.email ||
                                        accountAlert.message?.includes(
                                            'Email has already been taken'
                                        )
                                    }
                                    inverted
                                    size="medium"
                                    name="email"
                                    label="Email"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="email"
                                        placeholder="Your email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.email && <div className="error">{errors.email}</div>}
                                    {accountAlert.message?.includes(
                                        'Email has already been taken'
                                    ) && (
                                        <div
                                            className="error"
                                            dangerouslySetInnerHTML={{
                                                __html: accountAlert.message,
                                            }}
                                        />
                                    )}
                                </Input>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 xxl:col-span-3">
                                <Input
                                    error={!!errors.password}
                                    inverted
                                    size="medium"
                                    name="password"
                                    label="Password"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="password"
                                        placeholder="Create a password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.password && (
                                        <div className="error">{errors.password}</div>
                                    )}
                                    {accountAlert.message?.includes('Password is too short') && (
                                        <div
                                            className="error"
                                            dangerouslySetInnerHTML={{
                                                __html: accountAlert.message,
                                            }}
                                        />
                                    )}
                                </Input>
                            </div>
                            <div className="md:col-span-4 md:mb-sm xxl:col-span-2">
                                <Button
                                    buttonType="submit"
                                    type="primary"
                                    size="large"
                                    inverted={true}
                                    icon={<PlusIcon />}
                                    label="Signup"
                                    className="w-full"
                                />
                            </div>
                        </div>
                        {accountAlert.message &&
                            !accountAlert.message?.includes('Email has already been taken.') && (
                                <BodyText
                                    color={
                                        accountAlert.severity === 'error'
                                            ? '#ff3636'
                                            : accountAlert.severity === 'success'
                                            ? '#52cc61'
                                            : 'rgba(255,255,255,0.6)'
                                    }
                                    dangerouslySetInnerHTML={{ __html: accountAlert.message }}
                                />
                            )}
                    </form>
                )}
            </Formik>
        </div>
    );
};
RegisterForm.defaultProps = {
    setActiveForm: () => {},
    openModal: () => {},
    closeModal: () => {},
};

RegisterForm.propTypes = {
    setActiveForm: PropTypes.func,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
};

export default RegisterForm;
