import { useMemo, useContext } from 'react';
import { Formik } from 'formik';
import Cookies from 'js-cookie';

import SiteContext from 'context/SiteContext';

import { updateCustomer } from 'helpers/requests/customer-requests';

import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { BodyText } from 'components/atoms/Typography';
import { SaveIcon } from 'components/atoms/Icons';
import { genericErrorMessage } from 'helpers/constants/general';

const defaultValues = {
    firstName: '',
    lastName: '',
};

const UpdateCustomerNameForm = () => {
    const {
        store: { customer },
        setCustomer,
        setCustomerNameModalIsOpen,
    } = useContext(SiteContext);

    const initialValues = useMemo(
        () => ({
            ...defaultValues,
            ...(customer?.firstName && { firstName: customer.firstName }),
            ...(customer?.lastName && { lastName: customer.lastName }),
        }),
        [customer]
    );

    const assignErrors = customerUserErrors => ({
        firstName:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('firstName')
            )?.message || null,
        lastName:
            customerUserErrors?.find(customerUserError =>
                customerUserError.field.includes('lastName')
            )?.message || null,
    });

    const submitForm = async (values, { setSubmitting, setErrors, setStatus }) => {
        if (customer.firstName === values.firstName && customer.lastName === values.lastName) {
            setCustomerNameModalIsOpen(false);
            return;
        }

        setSubmitting(true);
        setStatus({
            error: null,
        });
        try {
            const { data } = await updateCustomer({
                customerAccessToken: Cookies.get('ZIA_SESS'),
                customer: values,
            });

            if (data?.customerUpdate?.customerUserErrors?.length > 0) {
                const customerUserErrors = data.customerUpdate.customerUserErrors;
                setErrors({
                    ...assignErrors(customerUserErrors),
                });
                return;
            }

            setCustomer(data.customerUpdate.customer);
            setCustomerNameModalIsOpen(false);
            setSubmitting(false);
        } catch (err) {
            console.error(`Error while updaing name; ${err}`);
            setStatus({
                error: genericErrorMessage,
            });
            setSubmitting(false);
        }
    };

    return (
        <div className="address-form-container pb-4">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'Required';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Required';
                    }

                    return errors;
                }}
                onSubmit={submitForm}
            >
                {({
                    values,
                    errors,
                    status,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form className="relative pb-4" autoComplete="off" onSubmit={handleSubmit}>
                        <div className="flex flex-col lg:grid grid-cols-12 gap-x-40px mb-xs md:mb-md">
                            <div className="fixed-header border-b border-white/20 border-white pb-m-sm md:pb-0 md:border-0 md:col-span-2 xl:col-span-2">
                                <BodyText
                                    color="#fff"
                                    fontSize="24px"
                                    fontSizeMobile="16px"
                                    className="ml-sm md:ml-0"
                                >
                                    Edit Name
                                </BodyText>
                            </div>
                        </div>
                        <div className="flex flex-col pt-m-lg pb-md md:py-0 md:grid grid-cols-12 gap-x-40px gap-y-40px">
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Input
                                    error={!!errors.firstName}
                                    inverted
                                    size="medium"
                                    name="firstName"
                                    label="First Name *"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="firstName"
                                        placeholder="Your first name"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.firstName && (
                                        <div className="error">{errors.firstName}</div>
                                    )}
                                </Input>
                            </div>
                            <div className="mb-m-sm md:mb-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                                <Input
                                    error={!!errors.lastName}
                                    inverted
                                    size="medium"
                                    name="lastName"
                                    label="Last Name *"
                                    className="md:overflow-visible md:h-60px"
                                >
                                    <input
                                        name="lastName"
                                        placeholder="Your last name"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {!!errors.lastName && (
                                        <div className="error">{errors.lastName}</div>
                                    )}
                                </Input>
                            </div>
                            <div className="md:col-span-4 lg:col-span-3">
                                <Button
                                    type="primary"
                                    size="large"
                                    buttonType="submit"
                                    inverted={true}
                                    icon={<SaveIcon />}
                                    label="Save Name"
                                    className="w-full"
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                        {status?.error && (
                            <BodyText className="absolute top-full" color="#ff3636">
                                {status.error}
                            </BodyText>
                        )}
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default UpdateCustomerNameForm;
