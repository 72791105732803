export const countryOptions = {
    'United States': 'United States',
    Canada: 'Canada',
};

export const countryCode = {
    'United States': '+1',
    Canada: '+1',
};

export const provinceOptions = {
    'United States': {
        Alaska: 'Alaska',
        Alabama: 'Alabama',
        Arkansas: 'Arkansas',
        Arizona: 'Arizona',
        California: 'California',
        Colorado: 'Colorado',
        Connecticut: 'Connecticut',
        'District of Columbia': 'District of Columbia',
        Delaware: 'Delaware',
        Florida: 'Florida',
        Georgia: 'Georgia',
        Hawaii: 'Hawaii',
        Iowa: 'Iowa',
        Idaho: 'Idaho',
        Illinois: 'Illinois',
        Indiana: 'Indiana',
        Kansas: 'Kansas',
        Kentucky: 'Kentucky',
        Louisiana: 'Louisiana',
        Massachusetts: 'Massachusetts',
        Maryland: 'Maryland',
        Maine: 'Maine',
        Michigan: 'Michigan',
        Minnesota: 'Minnesota',
        Missouri: 'Missouri',
        Mississippi: 'Mississippi',
        Montana: 'Montana',
        'North Carolina': 'North Carolina',
        'North Dakota': 'North Dakota',
        Nebraska: 'Nebraska',
        'New Hampshire': 'New Hampshire',
        'New Jersey': 'New Jersey',
        'New Mexico': 'New Mexico',
        Nevada: 'Nevada',
        'New York': 'New York',
        Ohio: 'Ohio',
        Oklahoma: 'Oklahoma',
        Oregon: 'Oregon',
        Pennsylvania: 'Pennsylvania',
        'Puerto Rico': 'Puerto Rico',
        'Rhode Island': 'Rhode Island',
        'South Carolina': 'South Carolina',
        'South Dakota': 'South Dakota',
        Tennessee: 'Tennessee',
        Texas: 'Texas',
        Utah: 'Utah',
        Virginia: 'Virginia',
        Vermont: 'Vermont',
        Washington: 'Washington',
        Wisconsin: 'Wisconsin',
        'West Virginia': 'West Virginia',
        Wyoming: 'Wyoming',
    },
    Canada: {
        Alberta: 'Alberta',
        'British Columbia': 'British Columbia',
        Manitoba: 'Manitoba',
        'New Brunswick': 'New Brunswick',
        'Newfoundland and Labrador': 'Newfoundland and Labrador',
        'Nova Scotia': 'Nova Scotia',
        'Northwest Territories': 'Northwest Territories',
        Nunavut: 'Nunavut',
        Ontario: 'Ontario',
        'Prince Edward Island': 'Prince Edward Island',
        Québec: 'Québec',
        Saskatchewan: 'Saskatchewan',
        Yukon: 'Yukon',
    },
};
export const stateOptions = {
    'United States': {
        AK: 'Alaska',
        AL: 'Alabama',
        AR: 'Arkansas',
        AZ: 'Arizona',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DC: 'District of Columbia',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        IA: 'Iowa',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        MA: 'Massachusetts',
        MD: 'Maryland',
        ME: 'Maine',
        MI: 'Michigan',
        MN: 'Minnesota',
        MO: 'Missouri',
        MS: 'Mississippi',
        MT: 'Montana',
        NC: 'North Carolina',
        ND: 'North Dakota',
        NE: 'Nebraska',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NV: 'Nevada',
        NY: 'New York',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        PR: 'Puerto Rico',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VA: 'Virginia',
        VT: 'Vermont',
        WA: 'Washington',
        WI: 'Wisconsin',
        WV: 'West Virginia',
        WY: 'Wyoming',
    },
    Canada: {
        AB: 'Alberta',
        BC: 'British Columbia',
        MB: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland and Labrador',
        NS: 'Nova Scotia',
        NT: 'Northwest Territories',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Prince Edward Island',
        QC: 'Québec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
    },
};
