import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Cookies from 'js-cookie';

import { formatAdminCustomerId } from 'helpers';
import { customerReset } from 'helpers/requests/customer-requests';

import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { BodyText } from 'components/atoms/Typography';
import { SaveIcon } from 'components/atoms/Icons';
import { useRouter } from 'next/router';
import SiteContext from 'context/SiteContext';

const ResetPasswordForm = ({ openModal, closeModal }) => {
    const { setResetModalIsOpen } = useContext(SiteContext);

    const [initialValues, setInitialValues] = useState({
        password: '',
        confirm: '',
    });

    const router = useRouter();
    const [accountError, setAccountError] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('cust_id') && params.get('reset_token')) {
            openModal('reset');
        }
    }, []);

    const submitForm = useCallback((values, { setSubmitting }) => {
        router.prefetch('/account/dashboard');

        setAccountError(null);
        const params = new URLSearchParams(location.search);
        customerReset(
            formatAdminCustomerId(params.get('cust_id')),
            params.get('reset_token'),
            values.password
        ).then(res => {
            // set cookie with access token / exp.date
            const { customerReset } = res.data?.data;
            setSubmitting(false);
            if (customerReset?.customerUserErrors?.length) {
                // setAuthErrorMessage("Invalid credentials");
                setAccountError(customerReset.customerUserErrors[0].message);
            } else {
                Cookies.set('ZIA_USER', customerReset.customer.email, {
                    expires: new Date(customerReset.customerAccessToken.expiresAt),
                });
                Cookies.set('ZIA_SESS', customerReset.customerAccessToken.accessToken, {
                    expires: new Date(customerReset.customerAccessToken.expiresAt),
                });
                setResetModalIsOpen(false);
                router.push('/account/dashboard');
            }
        });
    }, []);
    return (
        <div className="reset-password-form-container">
            <div className="flex flex-col lg:grid grid-cols-12 mb-md">
                <div className="border-b border-white/20 border-white pb-m-sm md:pb-0 md:border-0 md:col-span-9">
                    <BodyText
                        color="#fff"
                        fontSize="24px"
                        fontSizeMobile="16px"
                        className="ml-sm md:ml-0"
                    >
                        Reset Password
                    </BodyText>
                </div>
                <div className="mt-xs lg:mt-0 col-span-3 flex justify-between md:justify-start">
                    <BodyText color="#fff" opacity="0.6" margin="0 10px 0 0">
                        Have an account?
                    </BodyText>
                    <BodyText
                        color="#fff"
                        link
                        onClick={() => {
                            closeModal('reset');
                            openModal('login');
                        }}
                        margin="0 40px 0 0"
                    >
                        Login
                    </BodyText>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors.password = 'Required';
                    }
                    if (values.password && values.password.length < 5) {
                        errors.password = 'Password is too short (minimum is 5 characters)';
                    }
                    if (values.password !== values.confirm) {
                        errors.confirm = 'Passwords must match';
                    }
                    return errors;
                }}
                onSubmit={submitForm}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 xl:col-span-7">
                                <div className="flex flex-col md:flex-row">
                                    <Input
                                        error={!!errors.password || !!accountError}
                                        inverted
                                        className="flex-1 mr-xs mb-sm md:mb-0 md:h-80px md:pb-xs md:overflow-visible"
                                        size="medium"
                                        label="New password"
                                    >
                                        <input
                                            name="password"
                                            type="password"
                                            placeholder="Your new password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {!!errors.password && (
                                            <div className="error">{errors.password}</div>
                                        )}
                                        {!!accountError && (
                                            <div className="error">{accountError}</div>
                                        )}
                                    </Input>
                                    <Input
                                        error={!!errors.confirm || !!accountError}
                                        inverted
                                        className="flex-1 mr-xs mb-sm md:mb-0 md:h-80px md:pb-xs md:overflow-visible"
                                        size="medium"
                                        label="Verify new password"
                                        inputType="password"
                                    >
                                        <input
                                            name="confirm"
                                            type="password"
                                            placeholder="Your new password"
                                            value={values.confirm}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {!!errors.confirm && (
                                            <div className="error">{errors.confirm}</div>
                                        )}
                                    </Input>
                                    <div>
                                        <Button
                                            type="primary"
                                            buttonType="submit"
                                            size="large"
                                            inverted={true}
                                            icon={<SaveIcon />}
                                            label="Save new password"
                                            maxWidth="285px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

ResetPasswordForm.defaultProps = {
    setActiveForm: () => {},
    openModal: () => {},
    closeModal: () => {},
};

ResetPasswordForm.propTypes = {
    setActiveForm: PropTypes.func,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
};

export default ResetPasswordForm;
