import React, { useContext, useEffect, useRef } from 'react';
import Link from 'components/atoms/Link';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import SiteContext from 'context/SiteContext';

import Image from 'components/atoms/Image';
import { BodyText, BodyTextAlt } from 'components/atoms/Typography';
import {
    AccountIcon,
    BurgerIcon,
    CaretDownIcon,
    CartIcon,
    LogoIcon,
    SearchIcon,
} from 'components/atoms/Icons';

import Modal from 'components/molecules/Modal';

import SearchForm from './ModalForms/SearchForm';
import LoginForm from './ModalForms/LoginForm';
import RegisterForm from './ModalForms/RegisterForm';
import ResetPasswordForm from './ModalForms/ResetPasswordForm';
import ForgotPasswordForm from './ModalForms/ForgotPasswordForm';
import AddressForm from './ModalForms/AddressForm';
import UpdateCustomerNameForm from './ModalForms/UpdateCustomerNameForm';
import { useRouter } from 'next/router';
import breakpoints from 'styles/breakpoints';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);
}

const HeaderWrapper = styled.header`
    --text-color: 0 0 0;
    --background: 255 255 255;
    --header-background-opacity: 1;
    position: sticky;
    top: var(--header-top-offset, 0px);
    z-index: ${props => (props.sidebarMenuOpen ? '1500' : '1100')};
    transition: background 0.2s, transform 0.2s;
    color: rgb(var(--text-color));
    background-color: rgba(var(--background) / var(--header-background-opacity));
    ${props =>
        props.menuColor === '#000' &&
        `
        box-shadow: 0 0 1px 0 #999;
    `}
    height: 57px;

    body.hideHeader & {
        transform: translateY(-100%);
    }

    .nav-items-divider {
        height: 1px;
        width: 100%;
        background: #c0c0c0;
        opacity: 0.5;
    }

    @media screen and ${breakpoints.xl} {
        height: 120.5px;
    }
    .megamenuWrapper {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        min-height: 300px;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        transform: translateY(30px);
        transition: all 0.25s ease-in-out;
        border-top: 1px solid #ccc;
    }
    .megamenu {
        &:not(.megamenu--v2) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        &--v2 {
            display: grid;
        }
        > li {
            flex: 0 0 auto;
        }
    }
    .menu__links {
        border-bottom: 1px solid transparent;
        &:hover {
            border-color: currentColor;
            .megamenuWrapper {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }

    .menu__link-label {
        height: 14px;
        background: rgb(var(--text-color));
        color: rgb(var(--background));
        padding: 0 3px;
        font-size: 0.8em;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: normal;
    }
`;

const Header = ({ closeAllModal, openModal }) => {
    const {
        store: {
            settings,
            checkout: { lineItems },
            sidebarMenuOpen,
            customerLoggedIn,
        },
        getUpdatedCheckout,
        setSidebarMenuOpen,
        setSidebarCartOpen,
    } = useContext(SiteContext);

    const router = useRouter();

    const ref = useRef(null);

    useEffect(() => {
        if (!ref?.current) {
            return;
        }
        gsap.to(ref.current, {
            scrollTrigger: {
                start: '+=20',
                toggleActions: 'play none play reverse',
                scrub: false,
                markers: false,
            },
            // backgroundColor: '#fff',
            // boxShadow: '0 0 1px 0 #999',
            // ease: 'none',
            onComplete: () => {
                if (!ref?.current) {
                    return;
                }
                ref.current.classList.add('is-solid');
            },
            onReverseComplete: () => {
                if (!ref?.current) {
                    return;
                }
                ref.current.classList.remove('is-solid');
            },
        });
    }, [ref]);

    useEffect(() => {
        if (!ref?.current) {
            return;
        }

        if (sidebarMenuOpen) {
            ref.current.classList.add('is-solid');
            return;
        }

        ref.current.classList.remove('is-solid');
    }, [sidebarMenuOpen, ref.current]);

    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId = null;
        const resizeListener = () => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(handleHeaderProperty, 150);
        };
        // set resize listener
        resizeListener();
        window.addEventListener('resize', resizeListener);

        document.addEventListener('DOMContentLoaded', resizeListener);
        window.addEventListener('load', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
            window.removeEventListener('DOMContentLoaded', resizeListener);
            window.removeEventListener('load', resizeListener);
        };
    }, []);

    const handleHeaderProperty = () => {
        if (document.body.contains(ref.current)) {
            document.documentElement.style.setProperty(
                '--header-height',
                ref.current.offsetHeight + 'px'
            );
        }
    };

    return settings?.header ? (
        <>
            <HeaderWrapper
                className="header w-full"
                ref={ref}
                sidebarMenuOpen={sidebarMenuOpen}
                style={{
                    '--header-transparent-text-color':
                        settings?.header?.transparentNavTextColor === 'DARK'
                            ? '0 0 0'
                            : '255 255 255',
                    '--header-transparent-background':
                        settings?.header?.transparentNavTextColor === 'DARK'
                            ? '255 255 255'
                            : '0 0 0',
                }}
            >
                <nav className="">
                    <div className="container">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <button
                                    className="block cursor-pointer xl:hidden py-5 pr-3"
                                    onClick={() => setSidebarMenuOpen(!sidebarMenuOpen)}
                                >
                                    <BurgerIcon />
                                </button>
                                <Link href="/" onClick={closeAllModal}>
                                    <LogoIcon />
                                </Link>
                            </div>
                            <ul className="header-links header-links-secondary flex flex-wrap items-center -mx-xxs">
                                <li className="hidden lg:flex">
                                    <button
                                        className="py-5 lg:px-2 xl:px-xxs"
                                        onClick={() => {
                                            setSidebarMenuOpen(false);
                                            openModal('search');
                                        }}
                                        disabled={router?.asPath?.includes('/search')}
                                        data-action="focus-modal-search"
                                    >
                                        <SearchIcon />
                                    </button>
                                </li>
                                {customerLoggedIn ? (
                                    <li className="flex">
                                        <Link
                                            className="py-5 lg:px-2 xl:px-xxs"
                                            href="/account/dashboard"
                                            onClick={closeAllModal}
                                        >
                                            <AccountIcon />
                                        </Link>
                                    </li>
                                ) : (
                                    <li className="flex">
                                        <button
                                            className="py-5  lg:px-2 xl:px-xxs"
                                            onClick={() => {
                                                setSidebarMenuOpen(false);
                                                openModal('register');
                                            }}
                                        >
                                            <AccountIcon />
                                        </button>
                                    </li>
                                )}
                                <li className="flex">
                                    <button
                                        className="py-5 px-xxs relative"
                                        onClick={async () => {
                                            await getUpdatedCheckout();
                                            setSidebarMenuOpen(false);
                                            router?.asPath !== '/cart' && setSidebarCartOpen(true);
                                        }}
                                    >
                                        <CartIcon />
                                        {lineItems?.length > 0 && (
                                            <span
                                                className="absolute right-2 top-5 w-2 h-2 rounded-full flex items-center justify-center"
                                                style={{ background: 'currentColor' }}
                                            />
                                        )}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {!sidebarMenuOpen && <div className="nav-items-divider"></div>}
                    <div className="container nav-items-container hidden lg:flex justify-between">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <ul className="header-links header-links-main hidden xl:flex items-center lg:-mx-2 xl:-ml-xxs">
                                    {settings.header?.links &&
                                        settings.header?.links.map(
                                            ({ title, url, _uid, sublinks, ...link }, index) => (
                                                <li
                                                    className={`flex items-center menu__links lg:mx-2 xl:mx-xxs
                                                      ${index === 0 ? '' : ''}`}
                                                    key={_uid}
                                                >
                                                    <Link
                                                        href={url}
                                                        passHref
                                                        onClick={closeAllModal}
                                                    >
                                                        <BodyTextAlt
                                                            font="subheading"
                                                            className="flex gap-1 py-6"
                                                            as="span"
                                                            color="currentColor"
                                                        >
                                                            {link?.label && (
                                                                <span className="menu__link-label">
                                                                    {link.label}
                                                                </span>
                                                            )}
                                                            {title}
                                                        </BodyTextAlt>
                                                    </Link>
                                                    {sublinks?.length > 0 ? (
                                                        <>
                                                            <CaretDownIcon
                                                                className="ml-1"
                                                                width="0.5rem"
                                                                height="0.5rem"
                                                            />
                                                            <div
                                                                className={`megamenuWrapper shadow-xl`}
                                                            >
                                                                <div className="container">
                                                                    <ul className="megamenu megamenu--v2 md:gap-x-40px grid grid-cols-12">
                                                                        {sublinks.map(
                                                                            ({
                                                                                title,
                                                                                url,
                                                                                _uid,
                                                                                image,
                                                                                ...sublink
                                                                            }) => (
                                                                                <li
                                                                                    key={_uid}
                                                                                    className="col-span-full lg:col-span-4 md:py-5"
                                                                                >
                                                                                    <div className="grid grid-cols-12 gap-y-6">
                                                                                        <Link
                                                                                            href={
                                                                                                url
                                                                                            }
                                                                                            onClick={
                                                                                                closeAllModal
                                                                                            }
                                                                                            className="col-span-5 xl:col-span-6"
                                                                                        >
                                                                                            {image?.filename !==
                                                                                                '' && (
                                                                                                <Image
                                                                                                    className="mb-4 w-full"
                                                                                                    src={
                                                                                                        image.filename
                                                                                                    }
                                                                                                    alt={
                                                                                                        image?.alt ||
                                                                                                        `${title} - featured image.`
                                                                                                    }
                                                                                                    width={
                                                                                                        430
                                                                                                    }
                                                                                                    height={
                                                                                                        430
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </Link>
                                                                                        <div className="lg:col-span-7 xl:col-span-6 lg:pl-3 xl:pl-6">
                                                                                            <Link
                                                                                                href={
                                                                                                    url
                                                                                                }
                                                                                                onClick={
                                                                                                    closeAllModal
                                                                                                }
                                                                                            >
                                                                                                <BodyText
                                                                                                    font="subheading"
                                                                                                    as="span"
                                                                                                >
                                                                                                    {
                                                                                                        title
                                                                                                    }
                                                                                                </BodyText>
                                                                                            </Link>
                                                                                            {sublink
                                                                                                ?.sublinks
                                                                                                ?.length >
                                                                                                0 && (
                                                                                                <SubMenuV2
                                                                                                    link={
                                                                                                        sublink
                                                                                                    }
                                                                                                    className="mt-4"
                                                                                                    closeAllModal={
                                                                                                        closeAllModal
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </li>
                                            )
                                        )}
                                </ul>
                            </div>
                        </div>
                        <div className="secondary-menu-items-container flex items-center">
                            <ul className="header-links header-links-secondary flex flex-wrap items-center lg:-mx-2 xl:-ml-xxs">
                                {settings.header?.rightLinks?.map(
                                    ({ title, url, _uid, sublinks }) => (
                                        <li
                                            className="menu__links hidden xl:flex items-center lg:mx-2 xl:mx-xxs"
                                            key={_uid}
                                        >
                                            <Link href={url} onClick={closeAllModal}>
                                                <BodyTextAlt
                                                    font="subheading"
                                                    as="span"
                                                    className="block py-6"
                                                    color="currentColor"
                                                >
                                                    {title}
                                                </BodyTextAlt>
                                            </Link>
                                            {sublinks?.length > 0 ? (
                                                <>
                                                    <CaretDownIcon
                                                        className="ml-1"
                                                        width="0.5rem"
                                                        height="0.5rem"
                                                    />
                                                    <div className="megamenuWrapper shadow-xl">
                                                        <div className="container">
                                                            <ul className="megamenu md:gap-x-40px">
                                                                {sublinks.map(
                                                                    ({
                                                                        title,
                                                                        url,
                                                                        _uid,
                                                                        image,
                                                                        ...sublink
                                                                    }) => (
                                                                        <li
                                                                            key={_uid}
                                                                            className="md:py-5"
                                                                        >
                                                                            <Link
                                                                                href={url}
                                                                                onClick={
                                                                                    closeAllModal
                                                                                }
                                                                            >
                                                                                {image?.filename !==
                                                                                    '' && (
                                                                                    <Image
                                                                                        className="mb-4 mx-auto"
                                                                                        src={
                                                                                            image.filename
                                                                                        }
                                                                                        alt={
                                                                                            image?.alt ||
                                                                                            `${title} - featured image.`
                                                                                        }
                                                                                        width={430}
                                                                                        height={430}
                                                                                    />
                                                                                )}
                                                                                <BodyText
                                                                                    font="subheading"
                                                                                    as="span"
                                                                                >
                                                                                    {title}
                                                                                </BodyText>
                                                                            </Link>
                                                                            {sublink?.sublinks
                                                                                ?.length > 0 && (
                                                                                <SubMenu
                                                                                    link={sublink}
                                                                                    className="mt-5"
                                                                                    closeAllModal={
                                                                                        closeAllModal
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </HeaderWrapper>
        </>
    ) : null;
};

const HeaderMainWrapper = () => {
    const {
        store: {
            addressModalIsOpen,
            searchModalIsOpen,
            loginModalIsOpen,
            registerModalIsOpen,
            forgotPwModalIsOpen,
            resetModalIsOpen,
            sidebarMenuOpen,
            customerNameModalIsOpen,
        },
        setSidebarMenuOpen,
        setAddressModalIsOpen,
        setSearchModalIsOpen,
        setLoginModalIsOpen,
        setRegisterModalIsOpen,
        setForgotPwModalIsOpen,
        setResetModalIsOpen,
        setCustomerNameModalIsOpen,
    } = useContext(SiteContext);
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('open')) {
            switch (params.get('open')) {
                case 'search':
                    setSearchModalIsOpen(true);
                    break;
                case 'login':
                    setLoginModalIsOpen(true);
                    break;
                case 'register':
                    setRegisterModalIsOpen(true);
                    break;
                case 'forgotpw':
                    setForgotPwModalIsOpen(true);
                    break;
                case 'reset':
                    setResetModalIsOpen(true);
                    break;
                case 'address':
                    setAddressModalIsOpen(true);
                    break;
                case 'customer-name':
                    setCustomerNameModalIsOpen(true);
                    break;
            }
        }
    }, []);
    const openModal = form => {
        switch (form) {
            case 'search':
                setLoginModalIsOpen(false);
                setRegisterModalIsOpen(false);
                setForgotPwModalIsOpen(false);
                setResetModalIsOpen(false);
                setAddressModalIsOpen(false);
                setCustomerNameModalIsOpen(false);
                setSearchModalIsOpen(true);
                break;
            case 'login':
                setRegisterModalIsOpen(false);
                setForgotPwModalIsOpen(false);
                setResetModalIsOpen(false);
                setAddressModalIsOpen(false);
                setSearchModalIsOpen(false);
                setCustomerNameModalIsOpen(false);
                setLoginModalIsOpen(true);
                break;
            case 'register':
                setLoginModalIsOpen(false);
                setForgotPwModalIsOpen(false);
                setResetModalIsOpen(false);
                setAddressModalIsOpen(false);
                setSearchModalIsOpen(false);
                setCustomerNameModalIsOpen(false);
                setRegisterModalIsOpen(true);
                break;
            case 'forgotpw':
                setLoginModalIsOpen(false);
                setRegisterModalIsOpen(false);
                setResetModalIsOpen(false);
                setAddressModalIsOpen(false);
                setSearchModalIsOpen(false);
                setCustomerNameModalIsOpen(false);
                setForgotPwModalIsOpen(true);
                break;
            case 'reset':
                setLoginModalIsOpen(false);
                setRegisterModalIsOpen(false);
                setForgotPwModalIsOpen(false);
                setAddressModalIsOpen(false);
                setSearchModalIsOpen(false);
                setCustomerNameModalIsOpen(false);
                setResetModalIsOpen(true);
                break;
            case 'address':
                setLoginModalIsOpen(false);
                setRegisterModalIsOpen(false);
                setForgotPwModalIsOpen(false);
                setResetModalIsOpen(false);
                setSearchModalIsOpen(false);
                setCustomerNameModalIsOpen(false);
                setAddressModalIsOpen(true);
                break;
            case 'customer-name':
                setLoginModalIsOpen(false);
                setRegisterModalIsOpen(false);
                setForgotPwModalIsOpen(false);
                setAddressModalIsOpen(true);
                setResetModalIsOpen(false);
                setSearchModalIsOpen(false);
                setCustomerNameModalIsOpen(false);
                break;
        }
    };
    const closeModal = form => {
        switch (form) {
            case 'search':
                setSearchModalIsOpen(false);
                break;
            case 'login':
                setLoginModalIsOpen(false);
                break;
            case 'register':
                setRegisterModalIsOpen(false);
                break;
            case 'forgotpw':
                setForgotPwModalIsOpen(false);
                break;
            case 'reset':
                setResetModalIsOpen(false);
                break;
            case 'address':
                setAddressModalIsOpen(false);
                break;
            case 'customer-name':
                setCustomerNameModalIsOpen(false);
                break;
        }
    };

    const closeAllModal = () => {
        if (addressModalIsOpen) {
            setAddressModalIsOpen(false);
        }
        if (searchModalIsOpen) {
            setSearchModalIsOpen(false);
        }
        if (loginModalIsOpen) {
            setLoginModalIsOpen(false);
        }
        if (registerModalIsOpen) {
            setRegisterModalIsOpen(false);
        }
        if (forgotPwModalIsOpen) {
            setForgotPwModalIsOpen(false);
        }
        if (resetModalIsOpen) {
            setResetModalIsOpen(false);
        }
        if (sidebarMenuOpen) {
            setSidebarMenuOpen(false);
        }
        if (customerNameModalIsOpen) {
            setCustomerNameModalIsOpen(false);
        }
    };

    return (
        <>
            <Header closeAllModal={closeAllModal} openModal={openModal} />
            <Modal
                elementId="search-modal"
                isOpen={searchModalIsOpen}
                closeModal={() => {
                    closeModal('search');
                }}
            >
                <SearchForm closeModal={closeModal} isModalOpen={searchModalIsOpen} />
            </Modal>
            <Modal
                elementId="login-modal"
                isOpen={loginModalIsOpen}
                closeModal={() => closeModal('login')}
            >
                <LoginForm openModal={openModal} closeModal={closeModal} />
            </Modal>
            <Modal
                elementId="register-modal"
                isOpen={registerModalIsOpen}
                closeModal={() => closeModal('register')}
            >
                <RegisterForm openModal={openModal} closeModal={closeModal} />
            </Modal>
            <Modal
                elementId="forgotpw-modal"
                isOpen={forgotPwModalIsOpen}
                closeModal={() => closeModal('forgotpw')}
            >
                <ForgotPasswordForm openModal={openModal} closeModal={closeModal} />
            </Modal>
            <Modal
                elementId="reset-modal"
                isOpen={resetModalIsOpen}
                closeModal={() => closeModal('reset')}
            >
                <ResetPasswordForm openModal={openModal} closeModal={closeModal} />
            </Modal>
            <Modal
                elementId="address-modal"
                isOpen={addressModalIsOpen}
                closeModal={() => closeModal('address')}
            >
                <AddressForm />
            </Modal>
            <Modal
                elementId="customer-name-modal"
                isOpen={customerNameModalIsOpen}
                closeModal={() => closeModal('customer-name')}
            >
                <UpdateCustomerNameForm />
            </Modal>
        </>
    );
};

const SubMenu = ({ link, closeAllModal, ...attrs }) => {
    return (
        <ul className="ml-4" {...attrs}>
            {link?.sublinks?.map(({ title, url, _uid, ...sublink }) => (
                <li key={_uid}>
                    <Link href={url} onClick={closeAllModal}>
                        <BodyText
                            font="subheading"
                            as="span"
                            className="opacity-50 hover:opacity-100"
                        >
                            {title}
                        </BodyText>
                    </Link>
                    {sublink?.sublinks?.length > 0 && (
                        <SubMenu link={sublink} closeAllModal={closeAllModal} />
                    )}
                </li>
            ))}
        </ul>
    );
};

SubMenu.propTypes = {
    link: PropTypes.object,
    closeAllModal: PropTypes.func,
};

const SubMenuV2 = ({ link, closeAllModal, ...attrs }) => {
    return (
        <ul {...attrs}>
            {link?.sublinks?.map(({ title, url, _uid, ...sublink }) => (
                <li key={_uid} className="mb-2">
                    <Link href={url} onClick={closeAllModal}>
                        <BodyText
                            font="subheading"
                            as="span"
                            className="opacity-50 hover:opacity-100"
                            fontSize="0.875rem"
                        >
                            {title}
                        </BodyText>
                    </Link>
                    {sublink?.sublinks?.length > 0 && (
                        <SubMenu link={sublink} closeAllModal={closeAllModal} />
                    )}
                </li>
            ))}
        </ul>
    );
};

SubMenuV2.propTypes = {
    link: PropTypes.object,
    closeAllModal: PropTypes.func,
};

export default HeaderMainWrapper;
