import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { BodyText } from 'components/atoms/Typography';
import { Formik } from 'formik';
import { initiateCustomerRecover } from 'helpers/requests/customer-requests';
import { MailIcon } from 'components/atoms/Icons';

const ForgotPasswordForm = ({ openModal, closeModal }) => {
    const [initialValues, setInitialValues] = useState({
        email: '',
    });
    const [accountStatus, setAccountStatus] = useState(null);
    const [accountError, setAccountError] = useState(null);
    const submitForm = useCallback(async (values, { setSubmitting }) => {
        setAccountStatus(null);
        setAccountError(null);

        try {
            const { data } = await initiateCustomerRecover(values.email);

            const { customerRecover } = await { ...data };
            setSubmitting(false);

            if (data.customerRecover === null) {
                setAccountError(
                    'Invalid email or unactivated account. If you are having trouble logging in, please use the Sign Up form to create an account or activate an existing account.'
                );
                return;
            }

            if (customerRecover?.customerUserErrors?.length > 0) {
                if (customerRecover.customerUserErrors[0]?.code === 'UNIDENTIFIED_CUSTOMER') {
                    setAccountError(
                        'Invalid email or unactivated account. If you are having trouble logging in, please use the Sign Up form to create an account or activate an existing account.'
                    );
                    return;
                }
                setAccountError(
                    customerRecover.customerUserErrors[0]?.message ||
                        'Something went wrong, please try again.'
                );
                return;
            }
            setAccountStatus('An email has been sent with further instructions.');
        } catch (err) {
            setSubmitting(false);
            console.error(`Error while recovering customer; ${err}`);
            setAccountError('Something went wrong, please try again.');
        }
    }, []);
    return (
        <div className="forgot-password-form-container px-m-md overflow-auto scrollbar-hidden">
            <div className="flex flex-col lg:grid gap-x-2 grid-cols-12 mb-sm lg:mb-md">
                <div className="border-b border-white/20 border-white pb-m-sm md:pb-0 md:border-0 lg:col-span-3 xl:col-span-3 mb-xs lg:mb-0">
                    <BodyText
                        color="#fff"
                        fontSize="24px"
                        fontSizeMobile="16px"
                        className="ml-sm md:ml-0"
                    >
                        Forgot Password?
                    </BodyText>
                </div>
                <div className="hidden md:block md:col-span-4 lg:col-span-3 xl:col-span-5">
                    <BodyText color="#fff" opacity="0.6">
                        Don’t worry, happens to the best of us. Let us help you. We will send a
                        reset link to your account email.
                    </BodyText>
                </div>
                <div className="flex flex-wrap flex-col sm:flex-row gap-x-4 justify-between md:mt-xs md:justify-start md:col-span-7 lg:col-span-6 lg:mt-0 lg:justify-end xl:col-span-4">
                    <div className="flex justify-between">
                        <BodyText color="#fff" opacity="0.6" margin="0 10px 0 0">
                            Don&apos;t have an account?
                        </BodyText>
                        <BodyText
                            color="#fff"
                            link
                            onClick={() => {
                                closeModal('login');
                                openModal('register');
                            }}
                            margin="0 40px 0 0"
                            marginMobile="0"
                        >
                            Sign up
                        </BodyText>
                    </div>
                    <div className="flex justify-between">
                        <BodyText color="#fff" opacity="0.6" margin="0 10px 0 0">
                            Have an account?
                        </BodyText>
                        <BodyText
                            color="#fff"
                            link
                            onClick={() => {
                                closeModal('forgotpw');
                                openModal('login');
                            }}
                            margin="0 40px 0 0"
                            marginMobile="0"
                        >
                            Login
                        </BodyText>
                    </div>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};

                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={submitForm}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row">
                            <div className="mb-m-md md:hidden">
                                <BodyText color="#fff" opacity="0.6">
                                    Don’t worry, happens to the best of us. Let us help you. We will
                                    send a reset link to your account email.
                                </BodyText>
                            </div>
                            <Input
                                error={!!errors.email || !!accountError}
                                inverted
                                className="mb-m-md md:mb-0 md:mr-sm md:h-80px md:pb-xs md:overflow-visible"
                                size="medium"
                                label="Email"
                                maxWidth="300px"
                            >
                                <input
                                    name="email"
                                    placeholder="Your email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {!!accountStatus && <div className="success">{accountStatus}</div>}
                                {!!errors.email && <div className="error">{errors.email}</div>}
                            </Input>
                            <div className="w-full md:w-auto">
                                <Button
                                    buttonType="submit"
                                    type="primary"
                                    size="large"
                                    inverted={true}
                                    icon={<MailIcon />}
                                    label="Send link"
                                    maxWidth="285px"
                                    className="w-full"
                                />
                            </div>
                        </div>

                        {!!accountError && <BodyText color="#ff3636">{accountError}</BodyText>}
                    </form>
                )}
            </Formik>
        </div>
    );
};

ForgotPasswordForm.defaultProps = {
    openModal: () => {},
    closeModal: () => {},
};

ForgotPasswordForm.propTypes = {
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
};

export default ForgotPasswordForm;
